import React, { useState, useEffect, useRef } from 'react';
import { Box, Text, Link, Image } from '@chakra-ui/react';
import NextLink from 'next/link';

import useTranslation from 'next-translate/useTranslation';
import { motion, useAnimation } from 'framer-motion';
import Tracking from 'utils/tracking';

const MotionText = motion(Text);

const FeaturedProduct = ({
  onlineExclusive,
  title,
  image,
  price,
  url,
  path,
  mastersku,
  wornImage,
  wornActive,
  showWornImageOnHover,
  discount_percentage,
  list_price_formatted,
  tags,
  category,
  normalPrice,
  stock_level,
  list_price,
  sku,
  list_name,
  last_price_change_date,
}) => {
  const { t } = useTranslation('common');
  const imageRef = useRef(null);
  const [loaded, setLoaded] = useState(false);

  const [hover, setHover] = useState(null);
  const controls = useAnimation();

  useEffect(() => {
    if (!loaded && imageRef.current?.complete) {
      setLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (showWornImageOnHover) {
      controls
        .start({
          opacity: 0,
          transition: { duration: 0 },
        })
        .then(() => {
          controls.start({
            opacity: 1,
            transition: { delay: 0.1 },
          });
        });
    }
  }, [wornActive, showWornImageOnHover]);

  const showNormalImage = showWornImageOnHover ? (wornActive && hover) || (!wornActive && !hover) : true;

  return (
    <Box
      padding={'0 4px'}
      pos="relative"
      as="div"
      data-productid={mastersku}
      onClick={() =>
        Tracking.selectItem(title, mastersku, normalPrice, url, category, stock_level, list_price, list_name, sku)
      }
    >
      {tags &&
        tags?.map(({ tid }, index) => {
          tid === 441 ? (
            <Image
              ref={imageRef}
              className="express-icon"
              width="80px"
              height="80px"
              src={'/assets/express.svg'}
              key={`express-${index}`}
              alt={`express-${index}`}
            />
          ) : null;
        })}
      <NextLink href={url || '#'} passHref prefetch={false}>
        <Link alt={title} pos="relative" as={'a'}>
          <Text as={'span'} display={'none'}>
            {title}
          </Text>
          <Box
            bgSize={'contain'}
            bgPosition={'center center'}
            bgRepeat={'no-repeat'}
            pos={'relative'}
            textAlign="center"
            onMouseEnter={() => {
              setHover(true);
              if (!showWornImageOnHover) return;
            }}
            onMouseLeave={() => {
              setHover(false);
              if (!showWornImageOnHover) return;
            }}
          >
            {tags?.map(({ tid }, index) => {
              return tid === 441 ? (
                <Box
                  as="span"
                  borderRadius="50%"
                  pos="absolute"
                  bottom="0"
                  right="0"
                  padding="3px"
                  backgroundColor="white"
                  key={`FeaturedTags-${index}`}
                >
                  <Image
                    width={{ base: '35px', md: '50px' }}
                    height={{ base: '35px', md: '50px' }}
                    src={'/assets/express.svg'}
                    alt="express icon"
                  />
                </Box>
              ) : null;
            })}
            <Box as="span" display={showNormalImage ? 'block' : 'none'} textAlign="center">
              {loaded ? null : <Box className="spinner" />}

              <Image
                ref={imageRef}
                width={'300px'}
                height={{ base: 'auto', sm: '300px' }}
                placeholder="blur"
                style={{
                  margin: 'auto',
                  boxSize: '300px',
                  objectFit: 'contain',
                  maxWidth: '100%',
                }}
                alt={title}
                src={image}
                display={loaded ? '' : 'none'}
                onLoad={() => setLoaded(true)}
              />
            </Box>

            {wornImage && showWornImageOnHover ? (
              <Box
                visibility={showNormalImage ? 'hidden' : 'visible'}
                height={showNormalImage ? 0 : 'auto'}
                textAlign="center"
              >
                {loaded ? null : <Box className="spinner" />}
                <Image
                  ref={imageRef}
                  width={'300px'}
                  height={{ base: 'auto', sm: '300px' }}
                  placeholder="blur"
                  style={{
                    margin: 'auto',
                    boxSize: '300px',
                    objectFit: 'contain',
                    maxWidth: '100%',
                  }}
                  alt={title}
                  src={wornImage}
                  display={loaded ? '' : 'none'}
                  onLoad={() => setLoaded(true)}
                />
              </Box>
            ) : null}
          </Box>
        </Link>
      </NextLink>
      <Box
        display={'flex'}
        flexDir={'column'}
        justifyContent={'space-between'}
        minH={{ base: '262px', md: '185px' }}
        ml="10px"
        textAlign="center"
      >
        <Box>
          {onlineExclusive && (
            <Text fontWeight={'bold'} fontSize={'14px'} color={'text.primary'}>
              {t('exclusive')}
            </Text>
          )}
          <NextLink href={url || '#'} passHref prefetch={false}>
            <Link>
              <MotionText fontSize="18px" fontWeight="normal" lineHeight="23px">
                {title}
              </MotionText>
            </Link>
          </NextLink>
          <Text fontSize={'14px'} color={'text.primary'}>
            {t('codeTag')}:{mastersku}
          </Text>
          <Box display={{ base: 'block', sm: 'flex' }} justifyContent="center" mt={{ base: '15px', sm: '0' }}>
            {!last_price_change_date && list_price_formatted ? (
              <Box
                as={'p'}
                fontSize={{ base: '16px', sm: '20px', lg: '22px' }}
                mb={{ base: '0', sm: '20px' }}
                mr={{ base: '0', sm: '12px' }}
                color={'lightGrey'}
              >
                <del>{list_price_formatted}</del>
              </Box>
            ) : (
              ''
            )}
            <Box display="inline-flex">
              <Box
                as={'p'}
                fontWeight={700}
                fontSize={{ base: '16px', sm: '20px', lg: '22px' }}
                mb={'20px'}
                color={'text.primary'}
                mr="12px"
              >
                {price}
              </Box>
              {discount_percentage ? (
                <Box
                  as={'p'}
                  fontWeight={700}
                  fontSize={{ base: '16px', sm: '20px', lg: '22px' }}
                  mb={'20px'}
                  color={'brand.900'}
                >
                  {discount_percentage}
                </Box>
              ) : (
                ''
              )}
            </Box>
          </Box>
        </Box>
        {/* {last_price_change_date && (
          <Box color={'gray'} fontSize={'14px'}>
            {t('lastPrice')}
            {`${list_price_formatted}`}
          </Box>
        )} */}
      </Box>
    </Box>
  );
};

export default FeaturedProduct;
